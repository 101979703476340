import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import RocketTwoTone from '@ant-design/icons/lib/icons/RocketTwoTone'
import { BodyLarge, H1 } from '@thesisedu/web/dist'

const FORM_URL = 'https://getform.io/f/ffd9995f-2935-4fd1-94fd-f24374a1368e'

export const ContactForm: React.FC = () => {
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  if (submitted) {
    return (
      <>
        <RocketTwoTone style={{ fontSize: 80, margin: '0 auto 40px auto' }} />
        <H1>Thanks!</H1>
        <BodyLarge>
          Thank you for your interest in Prodigies Academy. A representative from our sales team
          will be reaching out shortly.
        </BodyLarge>
      </>
    )
  }
  return (
    <Form
      form={form}
      layout={'vertical'}
      hideRequiredMark
      onFinish={async (values) => {
        setLoading(true)
        try {
          const formData = new FormData()
          Object.keys(values).forEach((key: string) => {
            formData.append(key, values[key])
          })
          const response = await fetch(FORM_URL, {
            method: 'POST',
            body: formData
          })
          if (!response.status.toString().startsWith('2')) {
            // eslint-disable-next-line no-console
            console.error(response.body)
            message.error('There was an error submitting your message.')
          } else {
            setSubmitted(true)
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
          message.error('There was an error submitting your message.')
        }
        setLoading(false)
      }}
    >
      <Form.Item
        name={'name'}
        label={'Name'}
        rules={[{ required: true, message: 'Your name is required.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'email'}
        label={'Email Address'}
        rules={[{ required: true, type: 'email', message: 'A valid email address is required.' }]}
      >
        <Input type={'email'} />
      </Form.Item>
      <Form.Item
        name={'site'}
        label={'School Name'}
        rules={[{ required: true, message: 'Please enter the school you represent.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={'message'} rules={[{ required: true, message: 'A message is required.' }]}>
        <Input.TextArea
          autoSize={{ minRows: 6, maxRows: 15 }}
          placeholder={'What would you like to say?'}
        />
      </Form.Item>
      <Form.Item>
        <Button type={'primary'} size={'large'} loading={loading} htmlType={'submit'}>
          Get in Touch
        </Button>
      </Form.Item>
    </Form>
  )
}
